<template>
  <div>
    <Header :logo="'hide'" back-title="Shopping Cart" />
    <c-box paddingTop="30px" paddingBottom="80px" px="20px">
      <c-box
        w="100%"
        maxWidth="1200px"
        mx="auto"
        v-chakra="{
          '> nav': {
            '@media(max-width: 767px)': {
              display: 'none !important',
            },
          },
        }"
      >
        <Breadcrumb
          :items="[
            { href: '/', label: 'Beranda' },
            {
              href: '#/cart',
              label: 'Keranjang',
            },
            { label: 'Checkout', isCurrent: true },
          ]"
        />
        <c-heading
          as="h2"
          fontSize="36px"
          fontWeight="600"
          color="black.900"
          marginBottom="40px"
          :display="['none', 'block']"
        >
          Checkout
        </c-heading>
        <c-grid
          w="100%"
          maxWidth="1200px"
          h="auto"
          mx="auto"
          :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
          gap="6"
        >
          <c-box
            w="100%"
            bg="white"
            boxShadow="4px 4px 50px 5px #0000000D"
            borderRadius="6px"
          >
            <c-flex
              w="100%"
              borderBottom="1px solid #F2F2F2"
              :py="['20px', '26px']"
              :px="['20px', '47px']"
              justifyContent="space-between"
              alignItems="center"
            >
              <c-heading
                :fontSize="['14px', '24px']"
                fontWeight="500"
                color="black.900"
              >
                Detail Program
              </c-heading>
              <c-link
                color="red.900"
                :fontSize="['12px', '16px']"
                as="router-link"
                :to="{ name: 'client.index', hash: '#program' }"
              >
                Ganti Program
              </c-link>
            </c-flex>
            <c-flex
              w="100%"
              borderBottom="1px solid #F2F2F2"
              :py="['20px', '26px']"
              :px="['20px', '47px']"
            >
              <c-box w="136px" h="136px" borderRadius="3px" mr="20px">
                <c-image :src="require('@/assets/img-program.png')" />
              </c-box>
              <c-box>
                <c-heading
                  as="h3"
                  fontFamily="Roboto"
                  fontWeight="500"
                  color="black.900"
                  :font-size="['14px', '18px']"
                  lineHeight="27px"
                >
                  {{ itemCheckout.productName }}
                  {{ itemCheckout.programService }}
                  <c-box d="block">{{ itemCheckout.duration }} Hari</c-box>
                </c-heading>
                <c-button
                  fontFamily="Roboto"
                  fontWeight="500"
                  right-icon="arrow-forward"
                  color="brand.900"
                  variant="link"
                  py="8px"
                  :font-size="['14px', '18px']"
                  @click="onDetailProduct(itemCheckout)"
                >
                  Selengkapnya
                </c-button>
                <c-text
                  fontWeight="500"
                  color="black.900"
                  :font-size="['16px', '24px']"
                  :lineHeight="['24px', '36px']"
                  marginTop="30px"
                >
                  Rp{{ Number(itemCheckout.productPrice).toLocaleString("id") }}
                </c-text>
              </c-box>
            </c-flex>
            <c-flex
              flex-direction="column"
              :py="['20px', '26px']"
              :px="['20px', '47px']"
            >
              <c-text>Kode Kupon</c-text>
              <form
                v-chakra
                @submit.prevent="onCheckCoupons"
                display="flex"
                align-items="center"
                mt="10px"
              >
                <c-input
                  type="text"
                  placeholder="Masukkan kode kupon"
                  bg="#F2F2F2"
                  border="1px solid #C4C4C4"
                  :is-invalid="invalidCoupon"
                  error-border-color="#FF0000"
                  border-radius="6px"
                  :style="
                    !invalidCoupon && invalidCoupon !== null
                      ? 'border-color: #008C81'
                      : ''
                  "
                  h="62px"
                  v-model="kuponValue"
                />
                <c-button
                  type="submit"
                  size="sm"
                  variant="ghost"
                  color="brand.900"
                  :font-size="['12px', '18px']"
                  pl="50px"
                  pr="50px"
                  :disabled="!kuponValue"
                >
                  Terapkan
                </c-button>
              </form>
              <c-flex v-if="!invalidCoupon" align-items="center" mt="17px">
                <c-image
                  :src="require('@/assets/icon-question-filled.svg')"
                  alt="icon question"
                />
                <c-text :font-size="['11px', '14px']" ml="10px">
                  Pelajari lebih lanjut
                </c-text>
              </c-flex>
              <c-flex v-if="invalidCoupon" align-items="center" mt="17px">
                <c-image
                  :src="require('@/assets/icon-alert-circle.svg')"
                  alt="icon question"
                />
                <c-text color="#EA4335" :font-size="['11px', '14px']" ml="10px">
                  Kode kupon tidak sesuai
                </c-text>
              </c-flex>
            </c-flex>
          </c-box>
          <c-box
            w="100%"
            bg="white"
            boxShadow="4px 4px 50px 5px #0000000D"
            borderRadius="6px"
          >
            <c-flex
              w="100%"
              borderBottom="1px solid #F2F2F2"
              :py="['20px', '26px']"
              :px="['20px', '47px']"
              justifyContent="space-between"
              alignItems="center"
            >
              <c-heading
                :font-size="['14px', '24px']"
                fontWeight="500"
                color="black.900"
              >
                Detail Pembayaran
              </c-heading>
            </c-flex>
            <c-flex
              flex-direction="column"
              :py="['20px', '26px']"
              :px="['20px', '47px']"
              v-chakra="{ gap: '10px' }"
              borderBottom="1px solid #F2F2F2"
            >
              <c-flex
                justify-content="space-between"
                alignItems="center"
                :mb="['10px', '49px']"
              >
                <c-text :font-size="['12px', '18px']" color="#555">
                  Subtotal
                </c-text>
                <c-text
                  :font-size="['12px', '18px']"
                  font-weight="500"
                  color="#111"
                >
                  Rp {{ Number(subtotal).toLocaleString("id") }}
                </c-text>
              </c-flex>
              <c-flex
                justify-content="space-between"
                alignItems="center"
                :mb="['10px', '49px']"
              >
                <c-text :font-size="['12px', '18px']" color="#555">
                  Potongan
                </c-text>
                <c-text
                  :font-size="['12px', '18px']"
                  font-weight="500"
                  color="#111"
                >
                  -Rp {{ Number(totalDiscount).toLocaleString("id") }}
                </c-text>
              </c-flex>
              <c-flex
                alignItems="center"
                justify-content="space-between"
                mb="49px"
              >
                <c-text :font-size="['12px', '18px']" color="#555">
                  Total Pembayaran
                </c-text>
                <c-text
                  :font-size="['16px', '36px']"
                  font-weight="500"
                  color="brand.900"
                >
                  Rp {{ Number(total).toLocaleString("id") }}
                </c-text>
              </c-flex>
            </c-flex>
            <c-flex py="26px" px="47px" pr="39px">
              <c-button
                variant-color="brand"
                w="100%"
                borderRadius="100px"
                size="lg"
                fontSize="16px"
                :isDisabled="!itemCheckout.id || isSubmitPayment"
                :is-loading="isSubmitPayment"
                loading-text="Submitting"
                @click="submitPayment"
              >
                Lakukan Pembayaran
              </c-button>
            </c-flex>
          </c-box>
        </c-grid>
      </c-box>
    </c-box>
    <Footer />
    <nav-bottom />
  </div>
</template>

<script>
import _ from "lodash"
import Header from "@/components/header/index.vue"
import Footer from "@/components/Footer.vue"
import Breadcrumb from "@/components/breadcrumb.vue"
import NavBottom from "@/components/nav-bottom"

import { mapActions, mapGetters, mapState } from "vuex"

export default {
  name: "CheckoutPage",
  components: {
    Header,
    Footer,
    Breadcrumb,
    NavBottom,
  },
  data() {
    return {
      kuponValue: "",
      kuponName: null,
      totalDiscount: 0,
      invalidCoupon: null,
      isSubmitPayment: false,
    }
  },
  computed: {
    ...mapState(["cart/discount"], ["cart/clientKey"]),
    ...mapGetters({
      axios: "axios",
      itemCheckout: "cart/itemCheckout",
    }),
    subtotal() {
      return this.itemCheckout.productPrice
    },
    total() {
      return this.subtotal - this.totalDiscount
    },
  },
  watch: {
    kuponValue: {
      handler(val) {
        if (!val) this.totalDiscount = 0
      },
    },
  },
  methods: {
    ...mapActions({
      onSubmitPayment: "cart/onSubmitPayment",
      detailProduct: "clients/getProductById",
      onOpenModal: "clients/onToggleModal",
    }),
    onCheckCoupons() {
      this.axios
        .get(
          `/v1/clients/coupons/${this.kuponValue}/${this.itemCheckout.productId}`
        )
        .then((it) => it.data.data)
        .then((it) => {
          this.kuponName = it.name
          if (it.type === "percent") {
            this.totalDiscount = (it.totalDiscount / 100) * this.subtotal
          } else {
            this.totalDiscount = it.totalDiscount
          }
          this.invalidCoupon = false
          this.$toast({
            title: "Congratulations!",
            description: "Your coupon has been change.",
            status: "success",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          })
        })
        .catch((err) => {
          this.invalidCoupon = true
          this.totalDiscount = 0
          this.$toast({
            title: "Failed",
            description: err.response.data
              ? err.response.data.message
              : err.response.message,
            status: "error",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          })
        })
    },
    submitPayment() {
      let params = {
        total: this.itemCheckout.productPrice - this.totalDiscount,
        subTotal: this.itemCheckout.productPrice,
        coupon_code: this.kuponValue,
        couponName: this.kuponName,
      }
      this.isSubmitPayment = true
      this.onSubmitPayment({ id: this.itemCheckout.id, product: params })
        .then((r) => r.data)
        .then((r) => {
          console.log("@submitted-payment", r)
          this.$router.push({ name: "client.payment" })
          this.isSubmitPayment = false
        })
        .catch((err) => {
          this.isSubmitPayment = false
          this.$toast({
            title: "Failed",
            description:
              err.response.data.message ?? "Ops! Something when wrong.",
            status: "error",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          })
        })
    },
    onDetailProduct(item) {
      this.$router.push({ name: "client.index", hash: "#program" })
      this.detailProduct(item.productId).then(() => this.onOpenModal(true))
    },
  },
  mounted() {
    if (_.isEmpty(this.itemCheckout))
      return this.$router.push({ name: "client.cart" })

    //change this to the script source you want to load, for example this is snap.js sandbox env
    const midtransScriptUrl = "https://app.sandbox.midtrans.com/snap/snap.js"
    //change this according to your client-key
    const myMidtransClientKey = this["cart/clientKey"]

    let scriptTag = document.createElement("script")
    scriptTag.src = midtransScriptUrl
    // optional if you want to set script attribute
    // for example snap.js have data-client-key attribute
    scriptTag.setAttribute("data-client-key", myMidtransClientKey)

    document.body.appendChild(scriptTag)
    return () => {
      document.body.removeChild(scriptTag)
    }
  },
}
</script>

<style>
</style>
